export class ProjectListItemDataObject {
	/**
	 * @param {string} id - DB ID of the project.
	 * @param {string} code - Unique code of the project.
	 * @param {string} name - Name of the project.
	 * @param {string} [description=''] - Description of the project.
	 * @param {string} [icon='cube'] - Icon symbol of the project.
	 * @param {string} [defaultTimezone=''] - Default timezone for the project used in message definitions.
	 * @param {string} [defaultFrequencyCappingPlanId=''] - DB ID of the default frequency capping plan for this project. 
	 * @param {number} ordinal - Position of the project in the list.
	 */
	constructor(
		id, code, name, description, icon = 'cube', defaultTimezone = '', defaultFrequencyCappingPlanId = '', ordinal
	) {
		this.id = id;
		this.code = code;
		this.name = name;
		this.description = description;
		this.icon = icon ? icon : 'cube';
		this.defaultTimezone = defaultTimezone ? defaultTimezone : '';
		this.defaultFrequencyCappingPlanId = defaultFrequencyCappingPlanId;
		this.ordinal = ordinal;
	}
}

