import {
	ioJsonAction,
	ioJsonDeleteItemAction,
	ioJsonFetchAction,
	ioJsonFetchItemAction, ioJsonManualAction,
	ioJsonSaveAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showContentLoadingNoTab, showLoading} from 'Core/helpers/loading';
import {getArray, getInteger, getString, isset} from 'Core/helpers/data';
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import * as messageDefinitionDataMap from "./dataMap/messageDefinition";
import * as channelListDataMap from "./dataMap/channelList";
import * as customFieldDataMap from "./dataMap/customField";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";
import {MessageDefinitionDataObject} from "./dataObjects";
import {StandardJsonResponseError} from "Core/errors";
import {
	CAMPAIGN_STATUS, MESSAGE_DEFINITION_STATUS,
	PUBLISH_CAMPAIGN_MESSAGE_DEFINITION_ERROR_CODE,
	PUBLISH_MESSAGE_DEFINITION_ERROR_CODE
} from "./const";
import {addErrorMessageAction} from 'Core/components/global/Message';
import {messages_default_auto_hide_after} from "Config/app";
import {translate} from "Core/i18n";
import Label from "Core/components/display/Label";
import React from "react";

/**
 * Reset all campaign Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetCampaignAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
}

/**
 * Fetch campaign list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} projectId - DB ID of the project to get the campaign list for.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchCampaignListAction = (
	abortCallback, projectId, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir,
		{projectId: projectId ? getInteger(projectId) : null}
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				// Load the previous page if there are no items on the page after one has been deleted
				if (pageNo > 1 && getArray(responseData, 'data').length === 0) {
					return fetchCampaignListAction(
						abortCallback, projectId, filter, (pageNo - 1), perPage, sortBy, sortDir
					)(dispatch);
				}
				
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load campaign list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} projectId - DB ID of the project to get the campaign list for.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadCampaignListAction = (
	abortCallback, projectId, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchCampaignListAction(abortCallback, projectId, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setCampaignListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear campaign list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearCampaignListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearCampaignListData());
}

/**
 * Fetch campaign item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the campaign item to fetch.
 * @param {string} [loadingGUIID] - GUI ID of the loading overlay to hide when done.
 * @return {function(*=): Promise<CampaignItemDataObject>}
 */
export const fetchCampaignItemAction = (abortCallback, id, loadingGUIID) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-by-id',
		id,
		undefined,
		loadingGUIID ? hideLoadingFunction(loadingGUIID) : undefined,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => itemDataMap.input(responseData?.data));
};

/**
 * Load campaign item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the campaign item to load.
 * @return {function(*=): Promise<CampaignItemDataObject>}
 */
export const loadCampaignItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#campaign-general');
	return fetchCampaignItemAction(abortCallback, id, loading)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setCampaignItem(responseData));
			return responseData;
		});
}

/**
 * Clear campaign item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearCampaignItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearCampaignItem());
};

/**
 * Create campaign item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {CampaignItemDataObject} item - Campaign item to save.
 * @param {ProjectListItemDataObject} project - Campaign project (used to get default values).
 * @return {function(*=): Promise<CampaignItemDataObject>} Promise that will resolve with the created item 
 * received from IO or undefined if creation failed.
 */
export const createCampaignItemAction = (abortCallback, item, project) => async dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'campaign/create',
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set the capping plane to the project's default capping plane
		.then(async campaign => {
			const defaultCappingPlan = getString(project, 'defaultFrequencyCappingPlanId');
			
			if (isset(campaign) && !!defaultCappingPlan) {
				await ioJsonAction(
					abortCallback,
					'defaultAuthorizedApi',
					'frequency-capping-plan/assign-plan-to-campaign',
					{campaignId: campaign.id, frequencyCappingPlanId: defaultCappingPlan},
				)(dispatch);
			}
			
			return campaign;
		});
};

/**
 * Update campaign item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the campaign item to update.
 * @param {CampaignItemDataObject} item - Campaign item to save.
 * @param {boolean} [cappingPlanChanged] - Flag that specify if capping plane has been changed.
 * @param {string} [newCappingPlaneId] - DB ID of the new capping plan if it was changed by the user.
 * @return {function(*=): Promise<CampaignItemDataObject>} Promise that will resolve with the updated item 
 * received from IO or undefined if updating failed.
 */
export const updateCampaignItemAction = (
	abortCallback, id, item, cappingPlanChanged, newCappingPlaneId
) => async dispatch => {
	const loading = showLoading('#campaign-general');
	
	if (cappingPlanChanged) {
		if (!!newCappingPlaneId) {
			await ioJsonAction(
				abortCallback,
				'defaultAuthorizedApi',
				'frequency-capping-plan/assign-plan-to-campaign',
				{campaignId: id, frequencyCappingPlanId: newCappingPlaneId},
			)(dispatch);	
		} else {
			await ioJsonAction(
				abortCallback,
				'defaultAuthorizedApi',
				'frequency-capping-plan/unassign-plan-from-campaign',
				{campaignId: id},
			)(dispatch);
		}
	}
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'campaign/update-by-id',
		{
			id,
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setCampaignItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => { 
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setCampaignItem(updatedItem));
			return updatedItem; 
		});
};

/**
 * Delete campaign item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - ID of the campaign item that will be deleted.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const deleteCampaignItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'campaign/delete-by-ids',
		[id],
		undefined,
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Publish campaign
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Campaign DB ID.
 * @return {function(*): Promise<{
 * 		messageDefinitionId: number,
 * 		errorDataList: {errorType: PublishMessageDefinitionError, errorValue: string}[]
 * 	}[] 
 * 	|
 * 	undefined
 * >}
 */
export const publishCampaignAction = (abortCallback, id) => dispatch => {
	return ioJsonManualAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/publish-by-id',
		{id},
	)(dispatch)
		// Update campaign status in Redux store
		.then(res => {
			if (res) dispatch(actionCreators[reducerStoreKey].setCampaignItemStatus(CAMPAIGN_STATUS.ACTIVE));
			return res;
		})
		.catch(error => {
			// Render error if it is not 'AbortError'
			// @note Error should be an object with 'message' field already translated and ready for display.
			if (error.name !== 'AbortError') {
				dispatch(addErrorMessageAction(
					error.message,
					messages_default_auto_hide_after,
					'',
					undefined,
					undefined,
					undefined,
					true,
					{
						id: 'publish-campaign-error-dialog',
						closeOnEscape: true,
						closeOnClickOutside: false,
						hideCloseBtn: true,
					},
					{
						title: translate(
							'campaign_dialog_title',
							'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors'
						),
					}
				));

				// Message definition errors
				if (
					error instanceof StandardJsonResponseError &&
					error.response.errorCode === PUBLISH_CAMPAIGN_MESSAGE_DEFINITION_ERROR_CODE
				) {
					return error.response;
				}
			}
		});
};

/**
 * Pause the specified campaign stopping all customer messages from being sent
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - DB ID of the campaign to pause.
 * @return {function(*): Promise<Object|undefined>}
 */
export const pauseCampaignAction = (abortCallback, campaignId) => dispatch => {
	const loading = showContentLoadingNoTab();
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/pause-by-id',
		{campaignId, action: 'PAUSE'},
		hideLoadingFunction(loading),
	)(dispatch)
		// Update campaign status in Redux store
		.then(res => {
			if (res) dispatch(actionCreators[reducerStoreKey].setCampaignItemStatus(CAMPAIGN_STATUS.STOPPED));
			return res;
		});
}

/**
 * Resume the specified campaign that will continue sending customer messages
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - DB ID of the campaign to resume.
 * @return {function(*): Promise<Object|undefined>}
 */
export const resumeCampaignAction = (abortCallback, campaignId) => dispatch => {
	const loading = showContentLoadingNoTab();
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/pause-by-id',
		{campaignId, action: 'RESUME'},
		hideLoadingFunction(loading),
	)(dispatch)
		// Update campaign status in Redux store
		.then(res => {
			if (res) dispatch(actionCreators[reducerStoreKey].setCampaignItemStatus(CAMPAIGN_STATUS.ACTIVE));
			return res;
		});
}


// Message definitions -------------------------------------------------------------------------------------------------
/**
 * Fetch campaign item message definitions list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - DB ID of the campaign to get message definitions for.
 * @return {function(*=): Promise<MessageDefinitionDataObject[]|undefined>}
 */
export const fetchMessageDefinitionsListAction = (abortCallback, campaignId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-message-definitions',
		{id: campaignId},
	)(dispatch)
		// Get mapped data from response data
		.then(r => {
			if (isSuccessful(r)) return getArray(r, 'data').map(i => messageDefinitionDataMap.input(i));
			else return undefined;
		});
};

/**
 * Load campaign message definitions list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - DB ID of the campaign to get message definitions for.
 * @param {string} [loadingSelector='#message-definitions'] - Loading overlay element selector. If empty string or null,
 * no loading overlay will be displayed.
 * @return {function(*=): Promise<MessageDefinitionDataObject|undefined>}
 */
export const loadMessageDefinitionsListAction = (
	abortCallback, campaignId, loadingSelector = '#message-definitions'
) => dispatch => {
	let loading;
	if (loadingSelector) loading = showLoading("#message-definitions");
	return fetchMessageDefinitionsListAction(abortCallback, campaignId)(dispatch)
		// Load data into Redux store
		.then(r => {
			if (r) dispatch(actionCreators[reducerStoreKey].setMessageDefinitions(r));
			if (loading) hideLoading(loading);
			return r;
		});
};

/**
 * Clear campaign message definitions list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearMessageDefinitionsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearMessageDefinitions());
}

/**
 * Create campaign message definition item
 * @note This will create an empty message definition.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - DB ID of the campaign to create a message definitions for. 
 * @return {function(*=): Promise<MessageDefinitionDataObject>} Promise that will resolve with the newly created message
 * definition item received from IO or undefined if creation failed.
 */
export const createMessageDefinitionItemAction = (abortCallback, campaignId) => dispatch => {
	const loading = showLoading('#message-definitions');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'campaign/add-message-definition',
		{
			id: null, 
			data: messageDefinitionDataMap.output(new MessageDefinitionDataObject(null, campaignId)),
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? messageDefinitionDataMap.input(responseData.data) : undefined);
};

/**
 * Update message definition item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {MessageDefinitionDataObject} item - Message definition data to update.
 * @return {function(*): Promise<MessageDefinitionDataObject|undefined>}
 */
export const updateMessageDefinitionItemAction = (abortCallback, item) => dispatch => {
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'campaign/update-message-definition',
		{
			id: item.id,
			data: messageDefinitionDataMap.output(item)
		},
		undefined,
		true,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? messageDefinitionDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].updateMessageDefinition(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => { 
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].updateMessageDefinition(updatedItem));
			return updatedItem; 
		});
};

/**
 * Delete campaign message definition item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the message definition item that will be deleted.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const deleteMessageDefinitionItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#message-definition-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'campaign/delete-message-definitions-by-ids',
		[id],
		undefined,
		hideLoadingFunction(loading),
	)(dispatch);
};

/**
 * Fetch all available channels list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {?string} [projectId=null] - DB ID of the project to get available channels for.
 * @return {function(*): Promise<MessageDefinitionChannelDataObject[]|undefined>}
 */
export const fetchAvailableChannelsAction = (abortCallback, projectId = null) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'channel/fetch-all',
		{projectId},
	)(dispatch)
		// Get mapped data from response data
		.then(r => {
			if (isSuccessful(r)) return getArray(r, 'data').map(c => channelListDataMap.input(c));
			else return undefined;
		});
};

/**
 * Fetch the list of custom fields that can be used alongside the message content
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {CampaignMessageContentType[]|null} [filter=null] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchCustomFieldsAction = (
	abortCallback, filter = null, pageNo = 1, perPage = 999999, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-custom-fields',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir,
	)(dispatch)
		// Get mapped data from response data
		.then(res => {
			if (isSuccessful(res)) return getArray(res, 'data').map(i => customFieldDataMap.input(i));
			else return undefined;
		});
};

/**
 * Publish a message definition
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - DB ID of the campaign.
 * @param messageDefinitionId - DB ID of the message definition.
 * @return {function(*): *}
 */
export const publishMessageDefinitionAction = (abortCallback, campaignId, messageDefinitionId) => dispatch => {
	return ioJsonManualAction(
		abortCallback, 
		'defaultAuthorizedApi',
		'campaign/publish-message-definitions-by-ids',
		{id: campaignId, messageDefinitionIds: [messageDefinitionId]},
	)(dispatch)
		// Update message definition status in Redux store
		.then(res => {
			if (res) {
				dispatch(actionCreators[reducerStoreKey].setMessageDefinitionStatus(
					messageDefinitionId, MESSAGE_DEFINITION_STATUS.PUBLISHED
				));
			}
			return res;
		})
		.catch(error => {
			// Render error if it is not 'AbortError'
			// @note Error should be an object with 'message' field already translated and ready for display.
			if (error.name !== 'AbortError') {
				// Message definition errors
				if (
					error instanceof StandardJsonResponseError && 
					error.response.errorCode === PUBLISH_MESSAGE_DEFINITION_ERROR_CODE
				) {
					dispatch(addErrorMessageAction(
						(
							<ul>
								{getArray(error, 'response.data[0].errorDataList').map((mdError, idx) =>
									<Label
										key={idx}
										element="li"
										content={translate(
											mdError.errorType,
											'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors.types',
											{field: translate(
												mdError.errorValue, 
												'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors.fields'
											)}
										)}
										supportHtml={true}
									/>
								)}
							</ul>
						),
						messages_default_auto_hide_after,
						'',
						undefined,
						undefined,
						undefined,
						true,
						{
							id: 'publish-message-definition-error-dialog',
							closeOnEscape: true,
							closeOnClickOutside: false,
							hideCloseBtn: true,
						},
						{
							title: translate(
								'message_definition_dialog_title', 
								'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors'
							),
							allowHtml: true,
							alignContent: 'left'
						}
					));
				}
				// Other errors
				else {
					dispatch(addErrorMessageAction(
						error.message, 
						messages_default_auto_hide_after,
						'', 
						undefined, 
						undefined, 
						undefined, 
						true,
						{
							id: 'publish-message-definition-error-dialog',
							closeOnEscape: true,
							closeOnClickOutside: false,
							hideCloseBtn: true,
						},
						{
							title: translate(
								'message_definition_dialog_title',
								'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors'
							),
						}
					));
				}
			}
		});
};

/**
 * Launch the campaign form the quick create wizard
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - ID of the campaign create by the wizard.
 * @param {MessageDefinitionDataObject} messageDefinition - Message definition data to save before publishing.
 * @return {function(*): Promise<{
 * 		messageDefinitionId: number,
 * 		errorDataList: {errorType: PublishMessageDefinitionError, errorValue: string}[]
 * 	}[]
 * 	|
 * 	undefined
 * >}
 */
export const launchCampaignAction = (abortCallback, campaignId, messageDefinition) => dispatch => {
	// Campaign is not saved because in wizard mode there is no way to edit the campaign since message definition popup
	// must be closed in order to change it, and when popup closes URL hash for the wizard mode will be removed from 
	// history making it impossible to go back to the wizard mode.
	
	// First we need to save the message definition in order for the campaign to be published since when campaign is 
	// published, all it's message definitions will be published as well
	return updateMessageDefinitionItemAction(abortCallback, messageDefinition)(dispatch)
		.then(async saveRes => {
			// Just return if message definition failed to save since error message will be handled by the action
			if (!isset(saveRes)) return undefined;
			// Publish the campaign an all its message definitions
			const publishRes = await publishCampaignAction(abortCallback, campaignId)(dispatch);
			// Just return if campaign failed to publish since error message will be handled by the action
			if (!isset(publishRes)) return undefined;
			
			// Update message definition status in Redux store
			dispatch(actionCreators[reducerStoreKey].setMessageDefinitionStatus(
				messageDefinition.id, MESSAGE_DEFINITION_STATUS.PUBLISHED
			));
			
			return publishRes;
		});
};

/**
 * Set message definition customer messages generating flag in Redux store for a specified messaged definition
 * 
 * @param {string} id - Message definition DB ID.
 * @param {boolean} isGenerating - Flag that specifies if message definition customer messages are being generated. 
 * @return {(function(*): void)|*}
 */
export const setMessageDefinitionCustomerMessagesGeneratingAction = (id, isGenerating) => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setMessageDefinitionGenerating(id, isGenerating));
};