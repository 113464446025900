/**
 * Data object representing the message deliveries list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class MessageDeliveriesListItemDataObject {
	/**
	 * @param {string} id - ID of the delivered customer message in DB.
	 * @param {string} externalId - External ID of the delivered customer message.
	 * @param {string} sender - Channel sender identifier.
	 * @param {string} senderName - Channel sender name.
	 * @param {string} country - Country of the recipient.
	 * @param {CampaignMessageContentType|''} messageType - Content type of the delivered message.
	 * @param {string} recipient - Recipient contact information (phone number, email, etc. depending on message type).
	 * @param {MessageContentDataObject} messageSourceData - Message content source data (not personalized).
	 * @param {string} messageSubject - Actual subject of the message sent to recipient (personalized).
	 * @param {string} messageText - Actual text of the message sent to recipient (personalized).
	 * @param {number} [messagePartsCount] - Total number of customer messages that were actually sent. This is relevant
	 * only for SMS messages since they can have multiple parts and actual messages sent can be more than the total.
	 * @param {boolean} fallbackUsed - Flag that specifies if fallback mechanism was used for this delivered message.
	 * @param {Date} creationDate - Date and time when customer message delivery was created by Ping.
	 * @param {Date} sentToProviderDate - Date and time when customer message delivery was created by the provider.
	 * @param {Date} deliveryDate - Date and time when customer message delivery report was received (message delivered
	 * successfully).
	 * @param {CustomerMessageStatus} status - Status of the delivered customer message. 
	 * @param {CustomerMessageSubStatus} subStatus - Sub-status of the delivered customer message.
	 */
	constructor(
		id, externalId, sender, senderName, country, messageType, recipient, messageSourceData, messageSubject, 
		messageText, messagePartsCount, fallbackUsed, creationDate, sentToProviderDate, deliveryDate, status, subStatus,
	) {
		this.id = id;
		this.externalId = externalId;
		this.sender = sender;
		this.senderName = senderName;
		this.country = country;
		this.messageType = messageType;
		this.recipient = recipient;
		this.messageSourceData = messageSourceData;
		this.messageSubject = messageSubject;
		this.messageText = messageText;
		this.messagePartsCount = messagePartsCount;
		this.fallbackUsed = fallbackUsed;
		this.creationDate = creationDate;
		this.sentToProviderDate = sentToProviderDate;
		this.deliveryDate = deliveryDate;
		this.status = status;
		this.subStatus = subStatus;
	}
}