/**
 * Data object representing the messages list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class MessagesListItemDataObject {
	/**
	 * @param {string} id - ID of the customer message in DB.
	 * @param {string} messageId - ID of the customer message used to get the deliveries.
	 * @param {string} recipient - Recipient contact information (depending on the message type).
	 * @param {MessagesListItemCustomerDataObject} customer - Customer associated to this customer message.
	 * @param {string} channelName - Name of the channel used.
	 * @param {string} clientName - Name of the client.
	 * @param {string} creator - Customer message creator.
	 * @param {CustomerMessageStatus} status - Status of the customer message.
	 * @param {CampaignMessageContentType|''} messageType - Content type of the message.
	 * @param {Date} scheduledDate - Date and time when customer message was scheduled by Ping.
	 * @param {Date} creationDate - Date and time when customer message was created by Ping.
	 * @param {Date} deliveryDate - Date and time when customer message report was received (message delivered 
	 * successfully).
	 * @param {MessagesListItemDeliveryDataObject[]} deliveries - Delivered message for this customer message.
	 */
	constructor(
		id, messageId, recipient, customer, channelName, clientName, creator, status, messageType, scheduledDate, 
		creationDate, deliveryDate, deliveries
	) {
		this.id = id;
		this.messageId = messageId;
		this.recipient = recipient;
		this.customer = customer;
		this.channelName = channelName;
		this.clientName = clientName; 
		this.creator = creator;
		this.status = status;
		this.messageType = messageType;
		this.scheduledDate = scheduledDate;
		this.creationDate = creationDate;
		this.deliveryDate = deliveryDate;
		this.deliveries = deliveries;
	}
}

export class MessagesListItemCustomerDataObject {
	/**
	 * @param {?string} [id=null] - Customer's DB ID.
	 * @param {string} [crmId=''] - Customer's external CRM ID.
	 * @param {string} [firstName=''] - Customer's first name.
	 * @param {string} [lastName=''] - Customer's last name.
	 * @param {string} [phone=''] - Customer's mobile phone number.
	 * @param {string} [email=''] - Customer's email address.
	 * @param {Date} [dateOfBirth=null] - Customer's date of birth.
	 */
	constructor(id = null, crmId = '', firstName = '', lastName = '', phone = '', email = '', dateOfBirth = null) {
		this.id = id;
		this.crmId = crmId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.phone = phone;
		this.email = email;
		this.dateOfBirth = dateOfBirth;
	}
}

export class MessagesListItemDeliveryDataObject {
	/**
	 * @param {string} id - ID of the delivered customer message in DB.
	 * @param {string} externalId - External ID of the delivered customer message.
	 * @param {string} country - Country of the recipient.
	 * @param {CampaignMessageContentType|''} messageType - Content type of the delivered message.
	 * @param {string} recipient - Recipient contact information (phone number, email, etc. depending on message type).
	 * @param {MessageContentDataObject} messageSourceData - Message content source data (not personalized).
	 * @param {string} messageSubject - Actual subject of the message sent to recipient (personalized).
	 * @param {string} messageText - Actual text of the message sent to recipient (personalized).
	 * @param {number} [messagePartsCount] - Total number of customer messages that were actually sent. This is relevant
	 * only for SMS messages since they can have multiple parts and actual messages sent can be more than the total.
	 * @param {boolean} fallbackUsed - Flag that specifies if fallback mechanism was used for this delivered message.
	 * @param {Date} creationDate - Date and time when customer message delivery was created by Ping.
	 * @param {Date} sentToProviderDate - Date and time when customer message delivery was created by the provider.
	 * @param {Date} deliveryDate - Date and time when customer message delivery report was received (message delivered 
	 * successfully).
	 */
	constructor(
		id, externalId, country, messageType, recipient, messageSourceData, messageSubject, messageText, 
		messagePartsCount, fallbackUsed, creationDate, sentToProviderDate, deliveryDate
	) {
		this.id = id;
		this.externalId = externalId;
		this.country = country;
		this.messageType = messageType;
		this.recipient = recipient;
		this.messageSourceData = messageSourceData;
		this.messageSubject = messageSubject;
		this.messageText = messageText;
		this.messagePartsCount = messagePartsCount;
		this.fallbackUsed = fallbackUsed;
		this.creationDate = creationDate;
		this.sentToProviderDate = sentToProviderDate;
		this.deliveryDate = deliveryDate;
	}
}