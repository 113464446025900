import styles from "./index.module.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import Label from 'Core/components/display/Label';
import localeList from '../../../i18n/locale';
import {find} from 'lodash';
import {default_country, icon_font_error_symbol} from 'Config/app';
import I18n from 'Core/i18n';
import {getPageActions} from 'Core/helpers/redux';
import * as actions from './actions';
import Icon from 'Core/components/display/Icon';
import Button, {BUTTON_STYLE} from 'Core/components/display/Button';
import {isSuccessful} from 'Core/helpers/io';

class UnsubscribePage extends PageComponent {
	i18n = null;
	
	constructor(props) {
		super(props, {
			layout: 'blank',
			domPrefix: 'unsubscribe-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			renderTitle: false,
		}, undefined);
		
		this.state = {
			/**
			 * Flag that specifies if token passed through URL is missing
			 * @type {boolean}
			 */
			tokenMissing: false,
			/**
			 * Flag that specifies if token passed through URL is invalid
			 * @type {boolean}
			 */
			tokenInvalid: false,
			/**
			 * Message to display for the invalid token passed through URL
			 */
			tokenInvalidMessage: '',
			/**
			 * Flag that specifies if unsubscribing was successful
			 * @type {?boolean} null means that checking is not done yet.
			 */
			success: null,
			/**
			 * Flag that specifies if user has unsubscribing
			 */
			cancel: false,
		};

		// Set the language based on the 'lang' URL parameter
		const locale = find(localeList, {locale: this.getUrlParam('lang')}) ?? default_country.defaultLocale;
		this.i18n = new I18n();
		this.i18n.setLocale(locale);
		this.i18n.loadTranslation(locale).then(() => {
			this.i18n.startEngine();
			
			// Set document title so that it will be translated using the newly loaded language
			document.title = this.t('page_title');
		});
		
		// Action methods
		this.unsubscribe = this.unsubscribe.bind(this);
		
		// Render methods
		this.renderMissingToken = this.renderMissingToken.bind(this);
		this.renderInvalidToken = this.renderInvalidToken.bind(this);
		this.renderSuccess = this.renderSuccess.bind(this);
		this.renderCancel = this.renderCancel.bind(this);
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		this.i18n.stopEngine();
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {any|void}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const token = this.getUrlParam('token');
		if (!token) return this.setState({tokenMissing: true});
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Send the unsubscribe request
	 * @return {Promise<Object>}
	 */
	unsubscribe() {
		const {unsubscribeAction} = this.props;
		
		// Try to get the token form URL 
		const token = this.getUrlParam('token');
		if (!token) return this.setState({tokenMissing: true});
		
		// Send the unsubscribe request
		return this.executeAbortableAction(unsubscribeAction, token)
			.then(res => this.setState({success: isSuccessful(res)}));
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render personalized logo
	 * @note No logo for now.
	 * @return {JSX.Element}
	 */
	renderLogo() {
		return (
			<div className={`${styles['logo']}`}></div>
		);
	}

	/**
	 * Render content for when token is missing
	 * @return {JSX.Element}
	 */
	renderMissingToken() {
		return (
			<div className={`${styles['messageWrapper']}`}>
				{this.renderLogo()}
				<div className={`${styles['notice']}`}>
					<Icon symbol="chain-broken" className="page-notice-title-icon error-color"/>
					<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('token_missing')} />
					<Label element="p" elementProps={{className: 'page-notice'}} content={this.t('token_missing_desc')} />
				</div>
			</div>
		);
	}

	/**
	 * Render content for when token is invalid
	 * @return {JSX.Element}
	 */
	renderInvalidToken() {
		return (
			<div className={`${styles['messageWrapper']}`}>
				{this.renderLogo()}
				<div className={`${styles['notice']}`}>
					<Icon symbol={icon_font_error_symbol} className="page-notice-title-icon error-color"/>
					<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('token_invalid')} />
					<Label element="p" elementProps={{className: 'page-notice'}} content={this.t('token_invalid_desc')} />
				</div>
			</div>
		);
	}

	/**
	 * Render content for when user was successfully unsubscribed
	 * @return {JSX.Element}
	 */
	renderSuccess() {
		return (
			<div className={`${styles['messageWrapper']}`}>
				{this.renderLogo()}
				<div className={`${styles['notice']}`}>
					<Icon symbol="check" className="page-notice-title-icon success-color"/>
					<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('success')} />
					<Label element="p" elementProps={{className: 'page-notice'}} content={this.t('success_desc')} />
					<br />
					<Label element="small" icon="info-circle" content={this.t('close_notice')} />
				</div>
			</div>
		);
	}

	/**
	 * Render content for when user cancels the unsubscribe request
	 * @return {JSX.Element}
	 */
	renderCancel() {
		return (
			<div className={`${styles['messageWrapper']}`}>
				{this.renderLogo()}
				<div className={`${styles['notice']}`}>
					<Icon symbol="heart" className="page-notice-title-icon error-color"/>
					<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('thanks_desc')} />
					<br />
					<Label element="small" icon="info-circle" content={this.t('close_notice')} />
				</div>
			</div>
		);
	}

	render() {
		const {tokenMissing, success, cancel} = this.state;

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				{
					tokenMissing ? this.renderMissingToken() :
					cancel ? this.renderCancel() :
					success === false ? this.renderInvalidToken() :
					success === true ? this.renderSuccess()
						:
						<div className={`unsubscribe-form ${styles['formWrapper']}`}>
							{this.renderLogo()}
							<Label 
								element="div" 
								elementProps={{className: styles['formTitle']}} 
								content={this.t('form_title')}
							/>
							<Label
								element="div"
								elementProps={{className: ''}}
								content={this.t('unsubscribe_confirm')}
							/>
							<div className={`${styles['formActions']}`}>
								<Button
									big={true}
									icon="check"
									label={this.t('unsubscribe')}
									displayStyle={BUTTON_STYLE.SUCCESS}
									onClick={() => this.unsubscribe()}
								/>
								<Button
									big={true}
									icon="times"
									label={this.t('doNotUnsubscribe')}
									displayStyle={BUTTON_STYLE.ERROR}
									onClick={() => this.setState({cancel: true})}
								/>
							</div>
						</div>
				}
			</div>
		));
	}
}

export * from "./config";
export default withRouter(connect(null, getPageActions(actions))(UnsubscribePage));