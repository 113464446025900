import "./index.css";

import React from "react";
import ProjectPageDataComponent from "../ProjectPageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getString, isset, trimArray} from 'Core/helpers/data';
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_create_symbol, icon_font_edit_symbol} from "Config/app";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {AsyncMountError} from "Core/errors";
import {getCurrentProjectFromUrl, getCurrentProjectIdFromUrl} from 'Helpers/project';
import {
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellDateTypeOptionsDataObject, DataTableCellTemplateTypeOptionsDataObject
} from 'Core/components/advanced/DataTable/DataTableCell/dataObjects';
import {getAppLocaleDateFormat} from "Core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME} from "Core/const/locale";
import {
	CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS,
	CAMPAIGN_MESSAGE_CONTENT_TYPES,
	CAMPAIGN_STATUS,
	CAMPAIGN_STATUSES
} from './const';
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import CreateItemDialog from "./dialogs/CreateItemDialog";
import CampaignCustomerMessageCount
	from 'Pages/apps/default/projectPages/campaign/components/CampaignCustomerMessageCount';
import {v4} from 'uuid';
import Breadcrumbs from 'Core/components/display/Breadcrumbs';
import CampaignWizardDialog from 'Components/dialogs/CampaignWizardDialog';
import {rtrimChar} from 'Core/helpers/string';
import {waitingFunction} from 'Core/helpers/function';
import AdvancedDropdown, {ADVANCED_DROPDOWN_POSITION} from 'Core/components/display/AdvancedDropdown';
import Icon from 'Core/components/display/Icon';
import {find} from 'lodash';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	projectList: selectors.projectSelector.getProjectList(state),
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getCampaignList(state),
	mainListPagination: selectors[reducerStoreKey].getCampaignListPagination(state),
	mainListSort: selectors[reducerStoreKey].getCampaignListSort(state),
	mainListFilter: selectors[reducerStoreKey].getCampaignListFilter(state),
});

class CampaignPage extends ProjectPageDataComponent {
	/**
	 * Current main list snapshot hash
	 * @note Hash is refreshed every time main list is loaded or reloaded.
	 * @type {*|string}
	 */
	mainListSnapshot = v4();
	
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			domPrefix: 'campaign-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			disableLoad: true,
		}, 'page_title');
		
		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Dialog methods
		this.openCreateItemDialog = this.openCreateItemDialog.bind(this);
		this.openCampaignWizardDialog = this.openCampaignWizardDialog.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			.then(() => {
				if (prevProps.location.pathname !== this.props.location.pathname) {
					const {resetCampaignAction} = this.props;
					
					// Clear all page data and load the page again
					resetCampaignAction();
					this.clear().then(() => this.loadPageData().catch(() => {}));
				}
			});
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {location, mainList, loadCampaignListAction} = this.props;
		const currentProjectIdFromUrl = getCurrentProjectIdFromUrl(location);

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); });
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadCampaignListAction, currentProjectIdFromUrl))
				.then(() => this.setValue('loading', false))
				.then(() => this.mainListSnapshot = v4());
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {location, loadCampaignListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const currentProjectIdFromUrl = getCurrentProjectIdFromUrl(location);
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadCampaignListAction, currentProjectIdFromUrl, oFilter, pageNo, perPage, sortBy, sortDir
		)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			})
			.then(() => this.mainListSnapshot = v4());
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {location, loadCampaignListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const currentProjectIdFromUrl = getCurrentProjectIdFromUrl(location);
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadCampaignListAction, currentProjectIdFromUrl, oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {location, loadCampaignListAction, mainListPagination, mainListFilter} = this.props;
		const currentProjectIdFromUrl = getCurrentProjectIdFromUrl(location);
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadCampaignListAction, currentProjectIdFromUrl, oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {location, loadCampaignListAction, mainListPagination, mainListSort} = this.props;
		const currentProjectIdFromUrl = getCurrentProjectIdFromUrl(location);
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(
				loadCampaignListAction, currentProjectIdFromUrl, oFilter, 1, perPage, sortBy, sortDir
			))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get router 'to' value of the pages 'item' sub-url
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string} id - Item ID.
	 * @return {string}
	 */
	getItemRedirectTo(id) { return `${this.getRouterPathUrl()}/${id}`; }
	
	
	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open create main list item dialog
	 */
	openCreateItemDialog() {
		const {openDialogAction, closeDialogAction, createCampaignItemAction, addSuccessMessageAction} = this.props;
		
		const dialogGUIID = openDialogAction('', CreateItemDialog, {
			onSave: data => {
				const {projectList} = this.props;
				const project = find(projectList, {id: data.projectId});
				
				// Create item
				this.executeAbortableAction(createCampaignItemAction, data, project)
					// Redirect to item edit URL and add a global success message
					.then(response => {
						if (isset(response)) {
							closeDialogAction(dialogGUIID);
							this.redirectToItem(response.id);
							addSuccessMessageAction(this.t('create_success_msg'));
						}
					});
			}
		}, {
			id: 'create-item-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 500
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	
	/**
	 * Open a dialog for creating new campaigns using a simplified wizard interface
	 * @type {CampaignMessageContentType} messageType - Type of message to create campaign for.
	 */
	openCampaignWizardDialog(messageType) {
		const {
			projectList, location, openDialogAction, createCampaignItemAction, createMessageDefinitionItemAction,
			updateMessageDefinitionItemAction, addSuccessMessageAction, closeDialogAction,
		} = this.props;
		const currentProject = getCurrentProjectFromUrl(projectList, location);

		const dialogGUIID = openDialogAction('', CampaignWizardDialog, {
			messageType,
			project: currentProject,
			/** @param {CampaignWizardDataObject} data */
			onSave: data => {
				const project = find(this.getProp('projectList'), {id: data.projectId});
				
				// Create campaign
				this.executeAbortableAction(createCampaignItemAction, data, project)
					.then(
						/**
						 * @param {CampaignItemDataObject} createdCampaign
						 * @return {Promise<void>}
						 */
						async createdCampaign => {
							if (!isset(createdCampaign)) return;

							// Create a message definition for the newly created campaign
							/** @type {MessageDefinitionDataObject} */
							const createdMessageDefinition = await this.executeAbortableAction(
								createMessageDefinitionItemAction, createdCampaign.id
							);
							if (!isset(createdMessageDefinition)) return;

							// Update message definition with basic data and add channels
							/** @type {MessageDefinitionDataObject} */
							const updatedMessageDefinition = await this.executeAbortableAction(
								updateMessageDefinitionItemAction, {
									...createdMessageDefinition,
									startDate: data.startDate,
									channels: data.channels,
								}
							);
							if (!isset(updatedMessageDefinition)) return;

							closeDialogAction(dialogGUIID);

							// Redirect to the newly created campaign
							this.redirectTo(
								`${rtrimChar(appConfig.routerPath, '/')}/project/${data.projectId}/campaign/` +
								`${createdCampaign.id}/#wizard`
							);

							// Open edit popup for the newly created messaged definition in wizard mode and open recipients tab
							waitingFunction(() => {
								const mdElem = document.getElementById(`md-${updatedMessageDefinition.id}`);
								if (!!mdElem) {
									mdElem.click();
									return true;
								}
							}, 1, 60000)
								.then(() => waitingFunction(() => {
									const tabButtonElem = document.querySelector(
										'#message-definition-popup .popup-tab.RecipientsTab'
									);
									if (!!tabButtonElem) {
										tabButtonElem.click();
										return true;
									}
								}, 1, 60000));

							addSuccessMessageAction(this.t(
								'create_success_msg', 'AppsSection.DefaultApp.projectPages.CampaignPage'
							));
						});
			},
		}, {
			id: 'campaign-wizard-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 650
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {CampaignListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions">
				<Tooltip
					tag="div"
					title={
						[CAMPAIGN_STATUS.IN_PREPARATION, CAMPAIGN_STATUS.ACTIVE].includes(row.status) ?
							this.t('edit_tooltip') :
							this.t('view_tooltip')
					}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={
							[CAMPAIGN_STATUS.IN_PREPARATION, CAMPAIGN_STATUS.ACTIVE].includes(row.status) ? 
								icon_font_edit_symbol :
								'eye'
						}
						onClick={() => this.redirectToItem(row.id)}
					/>
				</Tooltip>
			</div>
		);
	}

	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		// Do not render title if project does not exist
		if (!this.projectExists()) return null;
		
		const {location, projectList} = this.props;
		const {title} = this.state;
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromUrl(projectList, location);

		return (
			<h1 className="page-title with-actions">
				<div className="content">
					<Breadcrumbs
						includeHome={false}
						items={[
							{
								to: this.getProjectTo(),
								label: getString(currentProject, 'name')
							},
							{
								label: (title ? this.translate(title, this.titlePathPrefix) : ''),
							},
						]}
					/>
				</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>

					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('create_new_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<span onClick={() => this.openCreateItemDialog()} className="button action solid big">
								<Label icon={icon_font_create_symbol} content={this.t('create_new')}/>
							</span>
						</Tooltip>
					</div>

					<div className="action-button main-button">
						<Tooltip
							tag="div"
							title={this.t('quick_create_new_tooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<AdvancedDropdown
								label={
									<Button
										className="no-margin d-block"
										big={true}
										icon="rocket"
										label={this.t('quick_create_new')}
										displayType={BUTTON_DISPLAY_TYPE.SOLID}
										displayStyle={BUTTON_STYLE.ACTION}
									/>
								}
								boundRect={
									document.querySelector('.page-title.with-actions') ?
										document.querySelector('.layout-content').getBoundingClientRect() :
										undefined
								}
								defaultPosition={ADVANCED_DROPDOWN_POSITION.LEFT}
							>
								{CAMPAIGN_MESSAGE_CONTENT_TYPES.map(p =>
									<div key={p} className="dropdown-item" onClick={() => this.openCampaignWizardDialog(p)}>
										<Icon {...CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS[p]} />
										<Label content={this.t(p, 'constants.messageType')}/>
									</div>
								)}
							</AdvancedDropdown>

						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}

	render() {
		const {
			mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, toggleMainSidebarSizeAction
		} = this.props;
		const {limitToAvailableSpace} = this.state;

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{!this.projectExists() ? this.renderMissingProject() :
					<>
						{
							this.hasTranslation('page_short_description') && this.t('page_short_description') ?
								<div className="simple-page-description">
									<Label content={this.t('page_short_description')} supportHtml={true} />
								</div>
								: null
						}

						<SimpleStaticSearch
							styleName="compact"
							className="main-search rows-2"
							defaultCollapse={true}
							layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
							buttonProps={{
								displayStyle: BUTTON_STYLE.DEFAULT
							}}
							options={[
								new SimpleStaticSearchOptionObject(
									'name',
									this.t('nameField')
								),
								new SimpleStaticSearchOptionObject(
									'campaignStatus',
									this.t('statusField'),
									SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
									{
										isClearable: true,
										options: CAMPAIGN_STATUSES.map(i => ({label: this.tt(i, 'status'), value: i}))
									}
								),
								new SimpleStaticSearchOptionObject(
									'startDate',
									this.t('startDateField'),
									SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
									{
										valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
									}
								),
								new SimpleStaticSearchOptionObject(
									'endDate',
									this.t('endDateField'),
									SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
									{
										valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
									}
								),
							]}
							value={mainListFilter}
							title={(<Label icon="search" content={this.t('Search', 'general')} />)}
							applied={!this.isMainListFilterEmpty()}
							enableToolbar={true}
							enableResetButton={false}
							onChange={this.filterMainList}
							onRemove={this.removeMainListFilter}
							onToggle={visible => this.setState({limitToAvailableSpace: visible})}
							ref={node => { this.mainListFilterRef = node; }}
						/>

						<DataTable
							id="main-page-table"
							//className="standard sticky-last-column"
							className="standard"
							responsive={true}
							responsiveBreakpointName="bp-xl"
							limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
							highlightOnHover={true}
							columns={[
								{
									name: 'status',
									label: this.t('statusField'),
									dataType: DATA_TABLE_CELL_TYPE.ANY,
									dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
										content: row => (
											<Label
												element="span"
												elementProps={{className: `no-wrap tag campaign-status-${row.status}`}}
												content={this.tt(row.status, 'status')}
											/>
										),
									}),
									width: 1,
								},
								{
									name: 'name',
									label: this.t('nameField'),
									dataType: DATA_TABLE_CELL_TYPE.TEMPLATE,
									dataTypeOptions: new DataTableCellTemplateTypeOptionsDataObject({
										template: '<strong>[{$id}]</strong> {$name}',
										supportHtml: true,
									}),
								},
								{
									name: 'description',
									label: this.t('descriptionField'),
									dataType: DATA_TABLE_CELL_TYPE.ANY,
									dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
										content: row => <Label
											content={
												getString(row, 'description').substring(0, 200) +
												(getString(row, 'description').length > 200 ? ' ...' : '')
											}
										/>
									}),
								},
								{
									name: 'customer-messages-count-header',
									label: this.t('customerMessagesCount'),
									dataType: DATA_TABLE_CELL_TYPE.ANY,
									dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
										content: row => (
											<CampaignCustomerMessageCount key={this.mainListSnapshot} campaignId={row.id} />
										),
									}),
								},
								{
									name: 'startDate',
									label: this.t('startDateField'),
									dataType: DATA_TABLE_CELL_TYPE.DATE,
									dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
										outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.STANDARD),
										whiteSpace: 'nowrap',
									}),
									defaultValue: '—',
									width: 1,
								},
								{
									name: 'endDate',
									label: this.t('endDateField'),
									dataType: DATA_TABLE_CELL_TYPE.DATE,
									dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
										outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.STANDARD),
										whiteSpace: 'nowrap',
									}),
									defaultValue: '—',
									width: 1,
								},

								// {
								// 	dataType: DATA_TABLE_CELL_TYPE.ANY,
								// 	dataTypeOptions: {
								// 		standardWrapper: false,
								// 		content: this.renderActions,
								// 	},
								// 	stopPropagation: true,
								// 	width: 1,
								// }
							]}
							onRowClick={data => this.redirectToItem(data.id)}
							data={mainList}
							paginationType={PAGINATION_TYPE.STATIC}
							onSortByColumn={this.sortMainList}
							onPaginationClick={this.loadMainListPage}
							{...mainListPagination}
							{...mainListSort}
						/>
					</>
				}
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(CampaignPage);