import {cloneDeep, get} from 'lodash';
import {getBool, getNumber, getString, getStringForDisplay} from 'Core/helpers/data';
import {MessageDeliveriesListItemDataObject} from "../dataObjects";
import {CAMPAIGN_MESSAGE_CONTENT_TYPE} from 'Pages/apps/default/projectPages/campaign/const';
import * as messageSourceDataMap from 'Pages/apps/default/projectPages/campaign/dataMap/message';
import {getDate, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {MessageDeliveriesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new MessageDeliveriesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'externalMessageId'),
		getString(result, 'sender'),
		getString(result, 'senderName'),
		getString(result, 'country'),
		getString(result, 'messageType'),
		[
			CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS, 
			CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER
		].includes(getString(result, 'messageType')) ?
			!!getString(result, 'recipient') ? `+${getString(result, 'recipient')}` : '—' :
			getStringForDisplay(get(result, 'recipient')),
		messageSourceDataMap.input(get(result, 'messageContentData')),
		getString(result, 'subject'),
		getString(result, 'body'),
		getNumber(result, 'messagePartsNumber'),
		getBool(result, 'fallbackUsed'),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'sentToProviderDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'deliveryDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'messageStatus'),
		getString(result, 'messageSubStatus'),
	);

	return result;
}

// @note There is no output function since list is read only.