import {cloneDeep, get} from 'lodash';
import {getArray, getBool, getNumber, getString, getStringForDisplay} from 'Core/helpers/data';
import {
	MessagesListItemCustomerDataObject,
	MessagesListItemDataObject,
	MessagesListItemDeliveryDataObject
} from '../dataObjects';
import * as messageSourceDataMap from 'Pages/apps/default/projectPages/campaign/dataMap/message';
import {getDate, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';
import {CAMPAIGN_MESSAGE_CONTENT_TYPE} from 'Pages/apps/default/projectPages/campaign/const';
import {ltrimString} from 'Core/helpers/string';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {MessagesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	const messageType = getString(result, 'messageType');
	let recipient;
	switch (messageType) {
		case CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS:
		case CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER:
			const mobilePhoneNumber = get(result, 'customer.mobilePhoneNumber');
			recipient = (mobilePhoneNumber ? `+${ltrimString(mobilePhoneNumber, '+')}` : '—');
			break;
		case CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL: 
			recipient = getStringForDisplay(get(result, 'customer.email')); 
			break;
		default: 
			recipient = getStringForDisplay(get(result, 'customer.mobilePhoneNumber'));
	}
	
	result = new MessagesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'messageId'),
		recipient,
		new MessagesListItemCustomerDataObject(
			getString(result, 'customer.id'),
			getString(result, 'customer.externalCrmId'),
			getString(result, 'customer.firstName'),
			getString(result, 'customer.lastName'),
			getString(result, 'customer.mobilePhoneNumber'),
			getString(result, 'customer.email'),
			getDate(get(result, 'customer.dateOfBirth', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		),
		getString(result, 'channelName'),
		getString(result, 'clientName'),
		getString(result, 'creator'),
		getString(result, 'status'),
		messageType,
		getDate(get(result, 'scheduledDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'deliveryDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getArray(result, 'messageDeliveryDataList').map(d => new MessagesListItemDeliveryDataObject(
			getString(d, 'id'),
			getString(d, 'externalMessageId'),
			getString(d, 'country'),
			getString(d, 'messageType'),
			[CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS, CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER].includes(getString(d, 'messageType'))? 
				!!getString(d, 'recipient') ? `+${getString(d, 'recipient')}` : '—' :
				getStringForDisplay(get(d, 'recipient')),
			messageSourceDataMap.input(get(d, 'messageContentData')),
			getString(d, 'subject'),
			getString(d, 'body'),
			getNumber(d, 'messagePartsNumber'),
			getBool(d, 'fallbackUsed'),
			getDate(get(d, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
			getDate(get(d, 'sentToProviderDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
			getDate(get(d, 'deliveryDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		)),
	);

	return result;
}

// @note There is no output function since list is read only.