import "./index.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE, executeComponentCallback} from 'Core/components/DialogComponent';
import PropTypes from "prop-types";
import Label from 'Core/components/display/Label';
import {getBoolean, getString, isset} from 'Core/helpers/data';
import {BUTTON_STYLE} from 'Core/components/display/Button';
import TextInput from 'Core/components/input/TextInput';
import {FormField} from 'Core/components/advanced/FormWrapper';
import {FORM_FIELD_LABEL_POSITION} from 'Core/components/advanced/FormWrapper/FormField';

/**
 * Confirm dialog component
 */
class ConfirmDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: 'ConfirmDialog',
			alignContent: 'center',
			dialogType: DIALOG_TYPE.CONFIRM,
		});
		
		this.state = {
			/**
			 * Confirm text entered by the user
			 * @type {string}
			 */
			confirmText: '',
		};
	}

	/**
	 * Dialog no method
	 * @note This method should be called when dialog's no button is clicked. This method does not actually do much. It
	 * just triggers the onOk event.
	 * Overrider to close the dialog by default if 'onNo' event function is not specified in props.
	 */
	no() {
		const {dialogGUIID, onNo} = this.props;
		
		// Trigger component's 'onNo' event if it is defined
		if (typeof onNo === 'function') executeComponentCallback(this.props.onNo, dialogGUIID);
		// Close the dialog by default if 'onNo' event is not defined
		else this.close();
	}

	/**
	 * Render confirm dialog action buttons
	 * @note Confirm dialog has yes and no style buttons. Yes button will trigger onYes event and no button will trigger
	 * onNo event.
	 *
	 * @param {string|null} [yesLabel] - Label used for yes button. Default value will be loaded from translation file.
	 * @param {string|null} [yesIcon] - Optional icon used for yes button.
	 * @param {string|null} [noLabel] - Label used for no button. Default value will be loaded from translation file.
	 * @param {string|null} [noIcon] - Optional icon used for no button.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderConfirmActionButtons(yesLabel = undefined, yesIcon = undefined, noLabel = undefined, noIcon = undefined) {
		const {confirmText} = this.props;
		let buttons = [];

		buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (isset(yesLabel) && yesLabel !== null ? yesLabel : this.t('Yes', 'general')),
			icon: (yesIcon ? yesIcon : ''),
			timer: this.getProp('yesTimer'),
			onClick: this.yes,
			autofocus: true,
			disabled: !!confirmText && getString(this.state, 'confirmText') !== confirmText,
		});

		buttons.push({
			style: BUTTON_STYLE.ERROR,
			label: (isset(noLabel) && noLabel !== null ? noLabel : this.t('No', 'general')),
			icon: (noIcon ? noIcon : ''),
			onClick: this.no,
		});

		return this.renderActionButtons(buttons);
	}

	render() {
		const {confirmText, yesBtnLabel, noBtnLabel} = this.props;
		const message = (this.props.message ? this.props.message : this.t('default_message'));
		
		return this.renderDialog(
			this.renderHelpTitle(''),
			(
				<>
					<Label 
						element="div"
						elementProps={{className: 'confirm-message'}}
						content={message} 
						supportHtml={getBoolean(this.props, 'supportHtml')} 
					/>
					{!!confirmText ?
						<FormField
							className="confirm-text-field"
							labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
							label={
								<Label 
									supportHtml={getBoolean(this.props, 'supportHtml')}
									content={this.t('confirm_text_label', '', '', {confirmText})}
								/>
							}
						>
							<TextInput 
								name="confirmText" 
								value={getString(this.state, 'confirmText')}
								onInput={e => this.setState({confirmText: getString(e, 'target.value')})}
								placeholder={this.t('confirm_text_placeholder')}
							/>
						</FormField>
						: null
					}
				</>
			),
			!!yesBtnLabel ? yesBtnLabel : undefined,
			'trash',
			!!noBtnLabel ? noBtnLabel : undefined,
			'times',
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ConfirmDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Confirm message string
	// @note This should already be translated.
	message: PropTypes.string,
	// Set to true to support HTML in 'message' prop.
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content.
	supportHtml: PropTypes.bool,
	// Number of seconds to disable 'yes' button before user can click it
	yesTimer: PropTypes.number,
	// Text that user must input to confirm
	// @note Input field will be rendered below the confirmation message inside the dialog that will be validated when 
	// 'yes' button is clicked. 
	confirmText: PropTypes.string,
	// Text to overwrite the default 'yes' button label
	yesBtnLabel: PropTypes.string,
	// Text to overwrite the default 'no' button label
	noBtnLabel: PropTypes.string,

	// Events
	onClose: PropTypes.func,
	onYes: PropTypes.func,
	onNo: PropTypes.func,
};

export default ConfirmDialog;