import {enUS, sr, srLatn, bg, bs, cs, el, hu, mk, ro, sk, sl, sq} from "date-fns/locale";
import {i18nRs, i18nRsCyr, i18nEl, i18nHu} from 'Core/components/input/PhoneInput/i18n';

/** @type {LocaleObj[]} */
let localeList = [];

/**
 * Bulgarian
 * @type {LocaleObj}
 */
export const bulgarian = {
	locale: 'bg-BG',
	name: 'Български',
	engName: 'Bulgarian',
	fileName: 'bg-BG.json',
	code2: 'bg',
	code3: 'bul',
	countryCode: 'BG',
	countryCodeIso3: 'BGR',
	numbers: {
		currency: {
			symbol: ' лв.',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'хил.',
			million: 'млн.',
			billion: 'млрд.',
			trillion: 'трлн.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: bg,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(bulgarian);

/**
 * Bosnian latin
 * @type {LocaleObj}
 */
export const bosnianLatin = {
	locale: 'bs-Latn-BA',
	name: 'Bosanski',
	engName: 'Bosnian',
	fileName: 'bs-Latn-BA.json',
	code2: 'bs',
	code3: 'bos',
	countryCode: 'BA',
	countryCodeIso3: 'BIH',
	numbers: {
		currency: {
			symbol: ' KM',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'hilj.',
			million: 'mil.',
			billion: 'mlrd.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: bs,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(bosnianLatin);

/**
 * Czech
 * @type {LocaleObj}
 */
export const czech = {
	locale: 'cs-CZ',
	name: 'Čeština',
	engName: 'Czech',
	fileName: 'cs-CZ.json',
	code2: 'cs',
	code3: 'cze',
	countryCode: 'CZ',
	countryCodeIso3: 'CZE',
	numbers: {
		currency: {
			symbol: ' Kč.',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'tis.',
			million: 'mil.',
			billion: 'mld.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: cs,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(czech);

/**
 * Greek
 * @type {LocaleObj}
 */
export const greek = {
	locale: 'el-GR',
	name: 'Ελληνικά',
	engName: 'Greek',
	fileName: 'el-GR.json',
	code2: 'el',
	code3: 'gre',
	countryCode: 'GR',
	countryCodeIso3: 'GRC',
	numbers: {
		currency: {
			symbol: ' €',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'χιλ.',
			million: 'εκ.',
			billion: 'δισ.',
			trillion: 'τρισ.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: el,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: i18nEl,
		},
	}
};
localeList.push(greek);

/**
 * English United States
 * @type {LocaleObj}
 */
export const englishUs = {
	locale: 'en-US',
	name: 'English (US)',
	engName: 'English (US)',
	fileName: 'en-US.json',
	code2: 'en',
	code3: 'eng',
	countryCode: 'US',
	countryCodeIso3: 'USA',
	numbers: {
		currency: {
			symbol: '$',
			position: 'left'
		},
		delimiters: {
			thousands: ',',
			decimal: '.'
		},
		abbreviations: {
			thousand: 'k',
			million: 'm',
			billion: 'b',
			trillion: 't'
		},
		ordinal: number => {
			const b = number % 10;
			return (~~ (number % 100 / 10) === 1) ?
				'th' :
				(b === 1) ? 'st' :
					(b === 2) ? 'nd' :
						(b === 3) ? 'rd' :
							'th'
				;
		},
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: enUS,

			// Date formats
			dateFormat: {
				short: 'MM/dd/yyyy',
				standard: 'MMM do, yyyy',
				long: 'EEEE, MMMM do, yyyy'
			},
			dateFormatAlt: {
				short: 'MM/dd/yyyy',
				standard: 'MMM do, yyyy',
				long: 'EEEE, MMMM do, yyyy'
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(englishUs);

/**
 * Croatian
 * @type {LocaleObj}
 */
export const croatian = {
	locale: 'hr-HR',
	name: 'Hrvatski',
	engName: 'Croatian',
	fileName: 'hr-HR.json',
	code2: 'hr',
	code3: 'hrv',
	countryCode: 'HR',
	countryCodeIso3: 'HRV',
	numbers: {
		currency: {
			symbol: ' €',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'tis.',
			million: 'mil.',
			billion: 'mlrd.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: cs,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(croatian);

/**
 * Hungarian
 * @type {LocaleObj}
 */
export const hungarian = {
	locale: 'hu-HU',
	name: 'Magyar',
	engName: 'Hungarian',
	fileName: 'hu-HU.json',
	code2: 'hu',
	code3: 'hun',
	countryCode: 'HU',
	countryCodeIso3: 'HUN',
	numbers: {
		currency: {
			symbol: ' Ft',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'ezer',
			million: 'millió',
			billion: 'milliárd',
			trillion: 'billió'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: hu,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: i18nHu,
		},
	}
};
localeList.push(hungarian);

/**
 * Macedonian
 * @type {LocaleObj}
 */
export const macedonian = {
	locale: 'mk-MK',
	name: 'Македонски',
	engName: 'Macedonian',
	fileName: 'mk-MK.json',
	code2: 'mk',
	code3: 'mac',
	countryCode: 'MK',
	countryCodeIso3: 'MKD',
	numbers: {
		currency: {
			symbol: ' ден',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'илј.',
			million: 'мил.',
			billion: 'млрд.',
			trillion: 'бил.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: mk,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(macedonian);

/**
 * Romanian
 * @type {LocaleObj}
 */
export const romanian = {
	locale: 'ro-RO',
	name: 'Română',
	engName: 'Romanian',
	fileName: 'ro-RO.json',
	code2: 'ro',
	code3: 'rum',
	countryCode: 'RO',
	countryCodeIso3: 'ROU',
	numbers: {
		currency: {
			symbol: ' lei',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'mii',
			million: 'mil.',
			billion: 'mld.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: ro,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(romanian);

/**
 * Slovak
 * @type {LocaleObj}
 */
export const slovak = {
	locale: 'sk-SK',
	name: 'Slovenčina',
	engName: 'Slovak',
	fileName: 'sk-SK.json',
	code2: 'sk',
	code3: 'slo',
	countryCode: 'SK',
	countryCodeIso3: 'SVK',
	numbers: {
		currency: {
			symbol: ' €',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'tis.',
			million: 'mil.',
			billion: 'mld.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: sk,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(slovak);

/**
 * Slovenian
 * @type {LocaleObj}
 */
export const slovenian = {
	locale: 'sl-SI',
	name: 'Slovenščina',
	engName: 'Slovenian',
	fileName: 'sl-SI.json',
	code2: 'sl',
	code3: 'slv',
	countryCode: 'SI',
	countryCodeIso3: 'SVN',
	numbers: {
		currency: {
			symbol: ' €',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'tisoč',
			million: 'mio.',
			billion: 'mrd.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: sl,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(slovenian);

/**
 * Albanian
 * @type {LocaleObj}
 */
export const albanian = {
	locale: 'sq-AL',
	name: 'Shqip',
	engName: 'Albanian',
	fileName: 'sq-AL.json',
	code2: 'sq',
	code3: 'alb',
	countryCode: 'AL',
	countryCodeIso3: 'ALB',
	numbers: {
		currency: {
			symbol: ' lek',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'mijë',
			million: 'mil.',
			billion: 'mld.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: sq,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: undefined,
		},
	}
};
localeList.push(albanian);

/**
 * Serbian Cyrillic
 * @type {LocaleObj}
 */
export const serbianCyrillic = {
	locale: 'sr-Cyrl-RS',
	name: 'Српски',
	engName: 'Serbian cyrillic',
	fileName: 'sr-Cyrl-RS.json',
	code2: 'sr',
	code3: 'srp',
	countryCode: 'RS',
	countryCodeIso3: 'SRB',
	numbers: {
		currency: {
			symbol: ' дин.',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'хиљ.',
			million: 'мил.',
			billion: 'млдр.',
			trillion: 'бил.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: sr,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: i18nRsCyr,
		},
	}
};
localeList.push(serbianCyrillic);

/**
 * Montenegrin
 * @type {LocaleObj}
 */
export const montenegrin = {
	locale: 'sr-Latn-ME',
	name: 'Crnogorski',
	engName: 'Montenegrin',
	fileName: 'sr-Latn-ME.json',
	code2: 'me',
	code3: 'cnr',
	countryCode: 'ME',
	countryCodeIso3: 'MNE',
	numbers: {
		currency: {
			symbol: ' €',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'hilj.',
			million: 'mil.',
			billion: 'mlrd.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: srLatn,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: i18nRs,
		},
	}
};
localeList.push(montenegrin);

/**
 * Serbian Latin (romanized)
 * @type {LocaleObj}
 */
export const serbianLatin = {
	locale: 'sr-Latn-RS',
	name: 'Srpski',
	engName: 'Serbian latin',
	fileName: 'sr-Latn-RS.json',
	code2: 'sr',
	code3: 'srp',
	countryCode: 'RS',
	countryCodeIso3: 'SRB',
	numbers: {
		currency: {
			symbol: ' din.',
			position: 'right'
		},
		delimiters: {
			thousands: '.',
			decimal: ','
		},
		abbreviations: {
			thousand: 'hilj.',
			million: 'mil.',
			billion: 'mlrd.',
			trillion: 'bil.'
		},
		ordinal: () => '.',
	},
	plugins: {
		// date-fns library
		dateFns: {
			// Locale
			// @description To make sure locale file for date-fns library is available in the app, it is imported and 
			// loaded into this value. This value contains the whole date-fns locale file for this locale.
			locale: srLatn,

			// Date formats
			dateFormat: {
				short: 'dd.MM.yyyy.',
				standard: 'dd. MMMM. yyyy.',
				long: 'EEEE, dd. MMMM yyyy.'
			},
			dateFormatAlt: {
				short: 'dd.MM.yyyy.',
				standard: "dd. MMMM'a'. yyyy.",
				long: "EEEE, dd. MMMM'a' yyyy."
			},

			// Time formats
			timeFormat: {
				short: 'HH:mm',
				standard: 'HH:mm:ss',
				long: 'HH:mm:ss.SSS'
			},

			// Date time separator string
			datetimeSeparator: ' '
		},

		// PhoneInput core input component
		phoneInputComponent: {
			// @note undefined will load English by default.
			localization: i18nRs,
		},
	}
};
localeList.push(serbianLatin);

export default localeList;

// Type definitions
/**
 * @typedef {object} LocaleObj
 * @property {string} locale - Locale code used by the app (Uses IETF BCP 47 language tag) consisting of (ex:
 * 'sr-Latn-BA', 'sr-Cyrl-RS', 'en-US'):
 * 	- lowercase, two-letter ISO 639-1 code (ex: 'sr'),
 * 	- [optional] Title Case ISO 15924 script name (ex: 'Latn'),
 * 	- [optional] UPPERCASE two-letter ISO 3166-1 alpha-2 country code (ex: 'RS')
 *
 * @property {string} name - Language name (ex: 'Srpski (latinica)').
 *
 * @property {string} engName - Language English name (ex: 'Serbian (romanized)').
 *
 * @property {string} fileName - Language file name, usually `${locale}.json` (ex: 'sr-Latn.json').
 *
 * @property {string} code2 - ISO 639-1 two-letter lowercase language code (ex: 'sr')
 * https://www.loc.gov/standards/iso639-2/php/code_list.php
 *
 * @property {string} code3 - ISO 639-2 three-letter lowercase language code (ex: 'srp')
 * @note If the language has both bibliographic and terminology codes, bibliographic should be specified used.
 * https://www.loc.gov/standards/iso639-2/php/code_list.php
 *
 * @property {string} countryCode - ISO 3166-1 alpha-2 two-letter uppercase country code (ex: 'RS')
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 *
 * @property {string} countryCodeIso3 - ISO 3166-1 alpha-3 three-letter uppercase country code (ex: 'SRB')
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
 *
 * @property {{
 * 	currency: {
 * 	   symbol: string,
 * 	   position: string
 * 	},
 * 	delimiters: {
 * 		thousands: string,
 * 		decimal: string
 * 	},
 * 	abbreviations: {
 * 	   thousand: string,
 * 	   million: string,
 * 	   billion: string,
 * 	   trillion: string
 * 	},
 * 	[ordinal]: Function<number>
 *	}} numbers
 *	@property {Object<string, {
 *	   locale: any,
 *	   dateFormat: {
 *	      short: string,
 *	      standard: string,
 *	      long: string
 *	   },
 *	   dateFormatAlt: {
 * 	   short: string,
 * 	   standard: string,
 * 	   long: string
 *	   },
 *	   timeFormat: {
 *	      short: string,
 *	      standard: string,
 *	      long: string
 *	   },
 *	   datetimeSeparator: string
 *	}>} [plugins] - Third-party library options (like date-fns).
 */